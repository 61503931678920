<template>
    <div class="login-container">
        <div class="login-top">
            <div>
                <img src="../assets/logo.png">
            </div>
        </div>
        <div class="login-form">
            <van-cell-group>
                <van-field
                        v-model="info.username"
                        left-icon="manager"
                        placeholder="用户名"
                        type="text"
                        clearable
                        autofocus=""
                        autocomplete="off"
                />
                <van-field
                        v-model="info.password"
                        left-icon="lock"
                        placeholder="密码"
                        type="password"
                        clearable
                        autocomplete="off"
                />
            </van-cell-group>
        </div>
        <div class="login-btn">
            <van-button type="info" size="large" icon="my iconfont icon-denglu" :disabled="!submitEnalbe"
                        @click="login">确认登录
            </van-button>
        </div>
        <div class="login-btn" v-show="ddLoginEnable">
            <van-button type="info" size="large" icon="my iconfont icon-dingding" @click="ddLogin">钉钉登录</van-button>
            <van-divider dashed>首次请使用钉钉登录</van-divider>
        </div>
        <div class="login-loading" v-show="loading">
            <van-loading size="24px" color="#1989fa" vertical>正在登录...</van-loading>
        </div>
    </div>
</template>
<script>
    import {mapMutations} from 'vuex'

    export default {
        name: 'login',
        data() {
            return {
                loading: false,
                info: {
                    username: '',
                    password: ''
                },
                submitEnalbe: false,
                ddLoginEnable: false,
                config: {
                    corpId: 'ding52b9df8b2ad47a4835c2f4657eb6378f'
                },
                code: '',
                redirect: this.$route.query.redirect || ''
            }
        },
        mounted: function () {
            this.init()
        },
        methods: {
            ...mapMutations(['changeLogin', 'changeAuth']),
            init() {
                if (this.$store.state.user.token) {
                    this.$router.push({
                        path: this.redirect ? this.redirect : '/'
                    })
                } else {
                    this.ddCheck();
                }
            },
            ddCheck() {
                if (this.$dd.env.platform !== 'notInDingTalk') {
                    let that = this;
                    that.$dd.ready(function () {
                        that.$dd.runtime.permission.requestAuthCode({
                            corpId: that.config.corpId, // 企业id
                            onSuccess: function (info) {
                                that.code = info.code; // 通过该免登授权码可以获取用户身份
                                that.ddLoginEnable = true;
                            }
                        })
                    })
                }
            },
            login() {
                let that = this;
                if (!that.submitEnalbe) return false;
                that.loading = true;
                that.$api.user.login({
                    username: that.info.username,
                    password: that.info.password
                }).then(response => {
                    that.loading = false;
                    let res = response.data;
                    that.doLogin(res);
                })
            },
            ddLogin() {
                let that = this;
                that.loading = true;
                that.$api.dingtalk.login({code: that.code}).then(response => {
                    that.loading = false;
                    let res = response.data;
                    that.doLogin(res);
                })
            },
            doLogin(res) {
                if (res.code == 1) {
                    this.changeLogin(res.data);
                    this.doLoadAuth();
                    this.$notify({type: 'primary', message: res.msg});
                    this.$router.push({
                        path: this.redirect ? this.redirect : '/'
                    });
                } else {
                    this.$notify({type: 'warning', message: res.msg});
                }
            },
            doLoadAuth() {
                let that = this;
                that.$api.node.loadAuth().then(response => {
                    that.changeAuth(response.data.data);
                });
            }
        },
        watch: {
            info: {
                handler: function (val) {
                    if (val.username.length > 0 && val.password.length > 0) {
                        this.submitEnalbe = true;
                    } else {
                        this.submitEnalbe = false;
                    }
                },
                deep: true
            }
        }
    }
</script>
<style scoped>

    .login-container {

    }

    .login-top {
        padding: 8rem 0;
        text-align: center;
    }

    .login-form {

    }

    .login-btn {
        margin: 10px 15px;
    }

    .login-btn .weui-btn {
        width: 100%;
    }

    .login-loading {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 2;
        top: 0;
        bottom: 0;
        background: #fff;
    }

    .login-loading .van-loading {
        margin-top: 50%;
    }
</style>
